import request from '@/utils/request';

// 获取培训包目录
export const packageFolder = params => request.get(`study/project/folder/tree`, params)

// 获取培训包列表
export const packageList = params => request.post(`study/package/list`, params)

// 获取培训包详情
export const packageDetail = id => request.get(`study/package/${id}/detail`)

// 收藏培训包
export const packageFavorite = params => request.post(`study/project/favorite`, params)

// 获取推荐培训包
export const packageRecommendList = params => request.get(`study/package/recommend`, params)

// 创建订单
export const packagePayQr = params => request.post(`study/purchase/task/wxqrcodepay`, params)

// 激活码激活
export const packageActivate = params => request.post(`study/purchase/task/activate`, params)

// 查询订单
export const packageOrderInfo = params => request.post(`study/purchase/task/orderstate`, params)

// 培训包评分
export const star = params => request.post(`study/package/star`, params)
